





















































































































































































































































































































































@import '../utilities/config';
.page__header__title {
  &::after {
    background-image: none;
  }
}

.v-formlist {
  &__table {
    margin: 15px 0;
    tr {
      background-color: gray('white');
    }
  }
  &__language {
    font-style: italic;
  }
  &__images {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    &-image {
      border: 1px solid gray('gray-150');
      max-width: 100%;
      height: auto;
    }
    &-title {
      margin-top: 15px;
      margin-bottom: 0;
    }
    &-text {
      font-size: 14px;
    }
  }
}
